import React, { useReducer, useState, useEffect } from "react";
import Table from "./Table";
import { GoogleLogin, GoogleLogout } from "react-google-login";
import { gapi } from "gapi-script";

import "./App.css";
/*
var lat = "37.5";
var lng = "-122.5";
var zoom = "14";
*/
var topzdb = "";
var topzdblist;

const formReducer = (state, event) => {
  return {
    ...state,
    [event.name]: event.value,
  };
};

function App() {
  const [profile, setProfile] = useState([]);

  const [data, setData] = useState([]);
  const [zdbList, setZdbList] = useState([]);
  const [zdb, setZdb] = useState("zdb0040-130");
  const [formData, setFormData] = useReducer(formReducer, {});
  const [submitting, setSubmitting] = useState(false);

  const clientId =
    "359683300609-9e8mtop8lijed81iml9o252hf7b66vs7.apps.googleusercontent.com";

  const zdbChange = (event) => {
    handleChange(event);
    console.log("zdb Change triggered for", event.target.value);
    setSubmitting(true);
    setZdb(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
  };

  const handleChange = (event) => {
    setFormData({
      name: event.target.name,
      value: event.target.value,
    });
  };
  /*
  const zdbOpts = [
    {
      value: "zdb-0300020",
      label: "zdb-0300020 -  Lethabong (Ekurhuleni) South Africa",
    },
    { value: "zdb0000-010", label: "zdb0000-010 -  Brunei Rd Kumasi Ghana" },
    {
      value: "zdb00000000",
      label: "zdb00000000 -  Abuja Federal Capital Territory Nigeria",
    },
    { value: "zdb0010-010", label: "zdb0010-010 - WCV+R Djenne Mali" },
    { value: "zdb0020-090", label: "zdb0020-090 -  Orlando FL USA" },
    { value: "zdb0020-100", label: "zdb0020-100 -  Houston TX USA" },
    {
      value: "zdb00200030",
      label: "zdb00200030 - Al Haram Mecca Saudi Arabia",
    },
    {
      value: "zdb00200040",
      label: "zdb00200040 - Unnamed Road JCMH+VJ، السيل الكبير Saudi Arabia",
    },
    {
      value: "zdb00200060",
      label: "zdb00200060 -  Karachi City Sindh Pakistan",
    },
    { value: "zdb0030-080", label: "zdb0030-080 -  Washington DC USA" },
    { value: "zdb0030-090", label: "zdb0030-090 -  Cincinnati OH USA" },
    { value: "zdb0030-100", label: "zdb0030-100 -  Irving TX USA" },
    { value: "zdb0030-110", label: "zdb0030-110 -  Northglenn CO USA" },
    { value: "zdb0030-120", label: "zdb0030-120 -  Culver City CA USA" },
    { value: "zdb0030-130", label: "zdb0030-130 -  Santa Clara CA USA" },
    {
      value: "zdb00300010",
      label: "zdb00300010 -  Rue de La Kasbah Kairouan Tunisia",
    },
    { value: "zdb00300050", label: "zdb00300050 -  Majlesi St MMP+VC Iran" },
    {
      value: "zdb00300070",
      label: "zdb00300070 -  Islamabad Islamabad Capital Territory Pakistan",
    },
    {
      value: "zdb0040-080",
      label: "zdb0040-080 -  Scarborough ON MS E Canada",
    },
    { value: "zdb0040-090", label: "zdb0040-090 -  London ON NH S Canada" },
    { value: "zdb0040-100", label: "zdb0040-100 -  Minneapolis MN USA" },
    { value: "zdb0040-130", label: "zdb0040-130 -  Redmond WA USA" },
    {
      value: "zdb00400000",
      label: "zdb00400000 - bis Pl. du Puits de l'Ermite Paris France",
    },
    { value: "zdb00400010", label: "zdb00400010 -  Roma RM Italy" },
    {
      value: "zdb0050-010",
      label: "zdb0050-010 -  Belgrave Middleway Birmingham B XS UK",
    },
    {
      value: "zdb00500000",
      label: "zdb00500000 - - Whitechapel Rd London E JQ UK",
    },
    {
      value: "zdb00500010",
      label: "zdb00500010 - Columbiadamm Berlin Germany",
    },
    {
      value: "zdb00600020",
      label: "zdb00600020 - Veturitori Helsinki Finland",
    },
  ];
*/
  // Using useEffect to call the API once mounted and set the data
  //const columns = useMemo(
  //  () => [
  const columns = [
    {
      Header: "Level",
      accessor: "lvl",
      width: 50,
      maxwidth: 50,
    },
    {
      Header: "Name",
      accessor: "name",
      width: 300,
    },
    {
      Header: "*****",
      accessor: "rating",
      width: 50,
    },
    {
      Header: "#",
      accessor: "reviews",
      width: 70,
    },
    {
      Header: "Address",
      accessor: "address",
      width: 300,
    },
    {
      Header: "URL",
      accessor: "url",
      width: 200,
    },
    {
      Header: "Mask",
      accessor: "mask",
      width: 50,
    },
    {
      Header: "Site",
      accessor: "website",
      width: 200,
    },
    {
      Header: "ID",
      accessor: "id",
      width: 0,
      show: false,
    },
    {
      Header: "Lat",
      accessor: "lat",
      width: 0,
      show: false,
    },
    {
      Header: "Lng",
      accessor: "lng",
      width: 0,
      show: false,
    },
  ];

  useEffect(() => {
    (async () => {
      const initClient = () => {
        gapi.client.init({
          clientId: clientId,
          scope: "",
        });
      };
      gapi.load("client:auth2", initClient);

      console.log("Fetching for zdb ", zdb);
      if (topzdb === zdb) {
        console.log("Same zdb, returning");
        return;
      }
      topzdb = zdb;

      setFormData({ name: "qopt", value: "P" });
      setSubmitting(false);

      var zlnk = "https://iqamah.org/mdb/zopt";
      fetch(zlnk)
        .then((resp) => {
          if (!resp.ok) {
            console.log("Error fetching zOpts: " + zlnk);
            console.log(`HTTP error: ${resp.status}`);
          }
          console.log("zOpts returned");
          return resp.json();
        })
        .then((msg) => {
          console.log("Got zOpts ", msg);
          setZdbList(msg);
          topzdblist = msg.zlist.map((zel, val) => {
            return (
              <option key={zel.db} value={zel.db}>
                {zel.db} - {zel.gist}
              </option>
            );
          });
        });

      //var site = `https://iqamah.org/search?${lat},${lng},${zoom}`;
      var site = `https://iqamah.org/mdb/${zdb}`;
      fetch(site)
        .then((resp) => {
          if (!resp.ok) {
            console.log("Error fetching site: " + site);
            console.log(`HTTP error: ${resp.status}`);
          }
          console.log("GetMs returned for zdb ", zdb);
          return resp.text();
        })
        .then((msg) => {
          if (msg[1] !== "|") console.log(msg);
          //console.log(msg);
          var ms = [];
          msg.split("\n").every((lin) => {
            var p = lin.split("|");
            if (p[0] && p[0] !== "") {
              var m = {
                lvl: p[0], // Level of masjid
                id: p[1], // Masjid ID
                url: p[2], // URL to scan Iqamah times. Next field is mask, ignore
                mask: p[3], // URL to scan Iqamah times. Next field is mask, ignore
                name: p[4], // Masjid name
                website: p[5], // Masjid Website
                address: p[6], // Address
                //phone: p[7], // Address
                lat: p[8], // Latitude
                lng: p[9], // Longitude
                rating: p[10],
                reviews: p[11],
                //timestamp: p[19], // Timestamp when masjid added to index
              };
              ms.push(m);
              return true;
            }
          });
          setData(ms);
        });
    })();
  }, [zdb]);

  const rowClickCB = (row) => {
    console.log("Row clicked: ", row.name, row.url, row.lvl, row.id);
    setFormData({ name: "name", value: row.name });
    setFormData({ name: "id", value: row.id });
    setFormData({ name: "url", value: row.url === "" ? row.website : row.url });
    setFormData({ name: "mask", value: row.mask });
    setFormData({ name: "level", value: row.lvl });
    setFormData({ name: "website", value: row.website });
    setFormData({ name: "lat", value: row.lat });
    setFormData({ name: "lng", value: row.lng });
    handleMaskDown();
    const url = "https://iqamah.org/getMasjid/" + row.id;
    fetch(url)
      .then((resp) => {
        setSubmitting(false);
        if (!resp.ok) {
          console.log("Error getting Iqamah times for: " + formData.name);
          setFormData({ name: "console", value: "Iqamah times unavailable" });
          console.log(`HTTP error: ${resp.status}`);
        }
        console.log("getMasjid returned");
        return resp.text();
      })
      .then((msg) => {
        if (msg && msg.includes("|")) {
          console.log(msg);
          const ts = msg.split("|")[0];
          const dstr = new Date(+ts).toLocaleString("en-US");
          console.log("Date: ", dstr);
          msg = msg + "Timestamp: " + dstr;
          setFormData({ name: "console", value: msg });
        }
      });
  };

  const handleUpdate = (event) => {
    const msg = "Calling Update service for " + formData.name;
    console.log(msg);
    setFormData({ name: "console", value: msg });
    event.preventDefault();
    setSubmitting(true);
    const url =
      "https://admin.iqamah.org/update/?" +
      "id=" +
      formData.id +
      "&lvl=" +
      formData.level +
      "&lat=" +
      formData.lat +
      "&lng=" +
      formData.lng +
      "&url=" +
      formData.url +
      "&msk=" +
      formData.mask +
      "";
    fetch(url)
      .then((resp) => {
        setSubmitting(false);
        if (!resp.ok) {
          console.log("Error updating: " + formData.name);
          setFormData({ name: "console", value: resp.status });
          console.log(`HTTP error: ${resp.status}`);
        }
        console.log("Update returned");
        return resp.text();
      })
      .then((msg) => {
        setFormData({ name: "console", value: msg });
      });
  };

  const parse = () => {
    const where = formData.url !== "" ? formData.url : formData.website;
    const msg = "Calling Parse service for " + where;
    console.log(msg);
    setFormData({ name: "console", value: msg });
    setSubmitting(true);
    const url =
      "https://admin.iqamah.org/parse/?site=" +
      where +
      "&mask=" +
      formData.mask;
    fetch(url)
      .then((resp) => {
        setSubmitting(false);
        if (!resp.ok) {
          console.log("Error fetching site: " + url);
          setFormData({ name: "console", value: resp.status });
          console.log(`HTTP error: ${resp.status}`);
        }
        console.log("Parse returned");
        return resp.text();
      })
      .then((msg) => {
        setFormData({ name: "console", value: msg });
      });
  };

  const openSite = () => {
    const url = formData.url !== "" ? formData.url : formData.website; /*
    setFormData({
      name: "console",
      value: `Opening site for ${formData.name}`,
    });*/
    window.open(url, "_iqamah", "noreferrer");
  };

  const quest = () => {
    const url = `https://admin.iqamah.org/quest/?${formData.qopt}|${formData.qplace}`;
    setSubmitting(true);
    setFormData({
      name: "console",
      value: `Questing for ${formData.qplace} in ${formData.qopt}`,
    });
    fetch(url)
      .then((resp) => {
        setSubmitting(false);
        if (!resp.ok) {
          console.log("Error fetching site: " + url);
          setFormData({ name: "console", value: resp.status });
          console.log(`HTTP error: ${resp.status}`);
        }
        console.log("Quest returned");
        return resp.text();
      })
      .then((msg) => {
        setFormData({ name: "console", value: msg });
      });
  };

  const handleMaskUp = () => {
    const msk =
      (formData.parser || "") +
      ":" +
      (formData.begin || "") +
      ":" +
      (formData.skip || "") +
      ":" +
      (formData.end || "") +
      ":" +
      (formData.cols || "") +
      ":" +
      (formData.f || "") +
      ":" +
      (formData.d || "") +
      ":" +
      (formData.a || "") +
      ":" +
      (formData.m || "") +
      ":" +
      (formData.i || "") +
      ":" +
      (formData.jbegin || "") +
      ":" +
      (formData.jskip || "") +
      ":" +
      (formData.jend || "") +
      ":" +
      (formData.jcols || "") +
      ":" +
      (formData.ja1 || "") +
      ":" +
      (formData.ji1 || "") +
      ":" +
      (formData.ja2 || "") +
      ":" +
      (formData.ji2 || "");
    setFormData({ name: "mask", value: msk });
  };

  const handleMaskDown = () => {
    const msk = formData.mask.split(":");
    setFormData({ name: "parser", value: msk[0] });
    setFormData({ name: "begin", value: msk[1] });
    setFormData({ name: "skip", value: msk[2] });
    setFormData({ name: "end", value: msk[3] });
    setFormData({ name: "cols", value: msk[4] });
    setFormData({ name: "f", value: msk[5] });
    setFormData({ name: "d", value: msk[6] });
    setFormData({ name: "a", value: msk[7] });
    setFormData({ name: "m", value: msk[8] });
    setFormData({ name: "i", value: msk[9] });
    setFormData({ name: "jbegin", value: msk[10] });
    setFormData({ name: "jskip", value: msk[11] });
    setFormData({ name: "jend", value: msk[12] });
    setFormData({ name: "jcols", value: msk[13] });
    setFormData({ name: "ja1", value: msk[14] });
    setFormData({ name: "ji1", value: msk[15] });
    setFormData({ name: "ja2", value: msk[16] });
    setFormData({ name: "ji2", value: msk[17] });
  };

  const onSuccess = (res) => {
    setProfile(res.profileObj);
  };

  const onFailure = (err) => {
    console.log("failed", err);
  };

  const logOut = () => {
    setProfile(null);
  };

  return (
    <div className="App">
      <div
        className="wrapper"
        style={{ display: "flex", flexDirection: "column" }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            marginLeft: 50,
          }}
        >
          <h1>Iqamah Admin Console</h1>
          {submitting && (
            <h1 className="serviceCall">... Calling Service ...</h1>
          )}
        </div>
        <form onSubmit={handleSubmit} className="form-wrapper">
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              height: 40,
            }}
          >
            <fieldset
              style={{
                display: "flex",
                flexDirection: "row",
              }}
            >
              <div className="zdbSel">
                <select
                  name="zdb"
                  onChange={zdbChange}
                  value={formData.zdb || ""}
                >
                  <option value="">Specify Zdb--</option>
                  {topzdblist}
                </select>
              </div>
              <label
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  marginLeft: 4,
                  marginRight: 4,
                }}
              >
                <p>Name</p>
                <input
                  name="name"
                  onChange={handleChange}
                  value={formData.name || ""}
                />
                <input
                  name="id"
                  type="hidden"
                  onChange={handleChange}
                  value={formData.id || ""}
                />
              </label>
              <label
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  marginRight: 4,
                }}
              >
                <p>URL</p>
                <input
                  name="url"
                  onChange={handleChange}
                  value={formData.url || ""}
                />
              </label>
              <label
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  marginRight: 4,
                }}
              >
                <p>Mask</p>
                <div className="mask">
                  <input
                    name="mask"
                    onChange={handleChange}
                    value={formData.mask || ""}
                  />
                </div>
              </label>
              <label
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  marginRight: 4,
                }}
              >
                <p>Level</p>
                <div className="tiny">
                  <select
                    name="level"
                    onChange={handleChange}
                    value={formData.level || ""}
                  >
                    <option value="">--Specify Masjid Level--</option>
                    <option value="0">0 - Remove</option>
                    <option value="1">1 - No Website</option>
                    <option value="2">2 - Has Website</option>
                    <option value="3">3 - Jumuah Timings</option>
                    <option value="4">4 - Iqamah Timings</option>
                    <option value="5">5 - Spreadsheet</option>
                    <option value="6">6 - Azaan+Iqamah Sheet</option>
                  </select>
                </div>
              </label>
              <button onClick={handleUpdate}>Update</button>
              <button onClick={parse}>Parse</button>
              <button onClick={openSite}>Open</button>
              <button onClick={quest}>Quest</button>
              <div className="qSel">
                <select
                  name="qopt"
                  onChange={handleChange}
                  value={formData.qopt || ""}
                >
                  <option value="P">P - Place</option>
                  <option value="N">N - Name</option>
                  <option value="C">C - Circle</option>
                  <option value="G">G - Grid</option>
                </select>
              </div>
              <label
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  marginLeft: 4,
                }}
              >
                <p>Place</p>
                <input
                  name="qplace"
                  onChange={handleChange}
                  value={formData.qplace || ""}
                />
              </label>
              <input
                name="website"
                onChange={handleChange}
                type="hidden"
                value={formData.website || ""}
              />
              <input
                name="lat"
                onChange={handleChange}
                type="hidden"
                value={formData.website || ""}
              />
              <input
                name="lng"
                onChange={handleChange}
                type="hidden"
                value={formData.website || ""}
              />
              <div className="console">
                <textarea
                  name="console"
                  value={formData.console || ""}
                  onChange={handleChange}
                ></textarea>
              </div>
            </fieldset>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              height: 40,
            }}
          >
            <fieldset
              style={{
                display: "flex",
                flexDirection: "row",
              }}
            >
              <label
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  marginRight: 4,
                }}
              >
                <p>Parser</p>
                <div className="tiny">
                  <input
                    name="parser"
                    onChange={handleChange}
                    value={formData.parser || ""}
                  />
                </div>
              </label>
              <label
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  marginRight: 4,
                }}
              >
                <p>Begin</p>
                <div className="mask">
                  <input
                    name="begin"
                    onChange={handleChange}
                    value={formData.begin || ""}
                  />
                </div>
              </label>
              <label
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  marginRight: 4,
                }}
              >
                <p>Skip</p>
                <div className="tiny">
                  <input
                    name="skip"
                    onChange={handleChange}
                    value={formData.skip || ""}
                  />
                </div>
              </label>
              <label
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  marginRight: 4,
                }}
              >
                <p>End</p>
                <div className="mask">
                  <input
                    name="end"
                    onChange={handleChange}
                    value={formData.end || ""}
                  />
                </div>
              </label>
              <label
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  marginRight: 4,
                }}
              >
                <p>Cols</p>
                <div className="tiny">
                  <input
                    name="cols"
                    onChange={handleChange}
                    value={formData.cols || ""}
                  />
                </div>
              </label>
              <label
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  marginRight: 4,
                }}
              >
                <p>F</p>
                <div className="tiny">
                  <input
                    name="f"
                    onChange={handleChange}
                    value={formData.f || ""}
                  />
                </div>
              </label>
              <label
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  marginRight: 4,
                }}
              >
                <p>D</p>
                <div className="tiny">
                  <input
                    name="d"
                    onChange={handleChange}
                    value={formData.d || ""}
                  />
                </div>
              </label>
              <label
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  marginRight: 4,
                }}
              >
                <p>A</p>
                <div className="tiny">
                  <input
                    name="a"
                    onChange={handleChange}
                    value={formData.a || ""}
                  />
                </div>
              </label>
              <label
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  marginRight: 4,
                }}
              >
                <p>M</p>
                <div className="tiny">
                  <input
                    name="m"
                    onChange={handleChange}
                    value={formData.m || ""}
                  />
                </div>
              </label>
              <label
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  marginRight: 4,
                }}
              >
                <p>I</p>
                <div className="tiny">
                  <input
                    name="i"
                    onChange={handleChange}
                    value={formData.i || ""}
                  />
                </div>
              </label>
              <label
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  marginRight: 4,
                }}
              >
                <p>JBegin</p>
                <div className="mask">
                  <input
                    name="jbegin"
                    onChange={handleChange}
                    value={formData.jbegin || ""}
                  />
                </div>
              </label>
              <label
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  marginRight: 4,
                }}
              >
                <p>JSkip</p>
                <div className="tiny">
                  <input
                    name="jskip"
                    onChange={handleChange}
                    value={formData.jskip || ""}
                  />
                </div>
              </label>
              <label
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  marginRight: 4,
                }}
              >
                <p>JEnd</p>
                <div className="mask">
                  <input
                    name="jend"
                    onChange={handleChange}
                    value={formData.jend || ""}
                  />
                </div>
              </label>
              <label
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  marginRight: 4,
                }}
              >
                <p>JCols</p>
                <div className="tiny">
                  <input
                    name="jcols"
                    onChange={handleChange}
                    value={formData.jcols || ""}
                  />
                </div>
              </label>
              <label
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  marginRight: 4,
                }}
              >
                <p>JA1</p>
                <div className="tiny">
                  <input
                    name="ja1"
                    onChange={handleChange}
                    value={formData.ja1 || ""}
                  />
                </div>
              </label>
              <label
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  marginRight: 4,
                }}
              >
                <p>JI1</p>
                <div className="tiny">
                  <input
                    name="ji1"
                    onChange={handleChange}
                    value={formData.ji1 || ""}
                  />
                </div>
              </label>
              <label
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  marginRight: 4,
                }}
              >
                <p>JA2</p>
                <div className="tiny">
                  <input
                    name="ja2"
                    onChange={handleChange}
                    value={formData.ja2 || ""}
                  />
                </div>
              </label>
              <label
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  marginRight: 4,
                }}
              >
                <p>JI2</p>
                <div className="tiny">
                  <input
                    name="ji2"
                    onChange={handleChange}
                    value={formData.ji2 || ""}
                  />
                </div>
              </label>
              <button onClick={handleMaskUp}>⬆️ Mask ⬆️</button>
              <button onClick={handleMaskDown}>⬇️ Mask ⬇️</button>
            </fieldset>
          </div>
        </form>
      </div>
      <div className="myTable">
        <Table columns={columns} data={data} cbCall={rowClickCB} />
      </div>
    </div>
  );
}

export default App;
